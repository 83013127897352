import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";

import StyledLink from "../components/ui/StyledLink";
import ContentColumn from "../components/ui/ContentColumn";
import Layout from "../components/layout/Layout";
import ProductDetails from "../components/product/ProductDetails";
import RecommendedProducts from "../components/product/RecommendedProducts";
import Seo from "../components/seo";
import * as styles from "./prodotto.module.css";
import ProductBadge from "../components/product/ProductBadge";
import LeafIcon from "../assets/leaf.svg";
import HandSoapIcon from "../assets/hand_soap.svg";
import RecycleIcon from "../assets/recycle.svg";
import SplitLayout from "../components/ui/banners/SplitLayout";
import Banner from "../components/ui/banners/Banner";

// TODO: fetch and use real data
import DUMMY_PRODUCTS from "../model/dummy_products";

const Prodotto = ({ pageContext }) => {
  const product = DUMMY_PRODUCTS.find(p => p.id === pageContext.productId);

  const breadcrumbProps = {
    crumbs: pageContext.breadcrumb.crumbs,
    crumbLabel: product.name,
  };

  return (
    <>
      <Seo title={`${product.name}`} description={`${product.description}`} />

      <Layout breadcrumbs={breadcrumbProps}>
        <ContentColumn>
          <div className={styles.productHeader}>
            <img className={styles.productImage} src={product.imageUrl} />
            <div className={styles.productData}>
              <h2>{product.name}</h2>
              <p>{product.description}</p>

              <div className={styles.badges}>
                {
                  // TODO: badges should be generated based on the product data
                }
                <ProductBadge
                  icon={<HandSoapIcon />}
                  description="Dermatologicamente testato"
                />
                <ProductBadge
                  icon={<LeafIcon />}
                  description="Biodegradabile"
                />
                <ProductBadge
                  icon={<RecycleIcon />}
                  description="Reciclabile"
                />
              </div>

              <StyledLink to="/404">Acquista ora</StyledLink>

              <p className={styles.productSmallText}>
                Loloremque laudantium, totam
              </p>
            </div>
          </div>

          <ProductDetails product={product} />
        </ContentColumn>

        <RecommendedProducts products={DUMMY_PRODUCTS.concat(DUMMY_PRODUCTS)} />

        <ContentColumn>
          <SplitLayout
            left={
              <Banner
                bg={
                  <StaticImage
                    src="../assets/bokeh_2.jpg"
                    alt=""
                    placeholder="blurred"
                  />
                }
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    justifyContent: "center",
                    margin: "auto 40px auto 40px",
                  }}
                >
                  <h3>Articolo</h3>
                  <p>
                    Sed ut perspiciatis unde omnis iste natu s error sit
                    voluptatem accusantium doloremque laudantium, totam rem
                    aperiam
                  </p>
                  <StyledLink to="/404">Scopri di più</StyledLink>
                </div>
              </Banner>
            }
            right={
              <StaticImage
                src="../assets/hands.jpg"
                alt=""
                placeholder="blurred"
                style={{ height: "100%" }}
              />
            }
          />
        </ContentColumn>
      </Layout>
    </>
  );
};

export default Prodotto;
